[class^='maj-icon-'],
[class*=' maj-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Majelan' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.maj-icon-gift:before {
  content: '\e900';
}
.maj-icon-mic:before {
  content: '\e92f';
}
.maj-icon-oval:before {
  content: '\e930';
}
.maj-icon-star:before {
  content: '\e931';
}
.maj-icon-lotus:before {
  content: '\e932';
}
.maj-icon-clock-off:before {
  content: '\e921';
  color: #c4c4c4;
}
.maj-icon-new:before {
  content: '\e922';
  color: #c0c1c5;
}
.maj-icon-book-filled:before {
  content: '\e92d';
}
.maj-icon-book:before {
  content: '\e92c';
}
.maj-icon-book-off:before {
  content: '\e92b';
  color: #c0c1c5;
}
.maj-icon-search:before {
  content: '\e927';
}
.maj-icon-search-off:before {
  content: '\e928';
}
.maj-icon-burger:before {
  content: '\e925';
}
.maj-icon-close-off:before {
  content: '\e926';
}
.maj-icon-logo:before {
  content: '\e904';
}
.maj-icon-home:before {
  content: '\e905';
}
.maj-icon-home-off:before {
  content: '\e91c';
}
.maj-icon-explore:before {
  content: '\e906';
}
.maj-icon-explore-off:before {
  content: '\e91d';
}
.maj-icon-library:before {
  content: '\e907';
}
.maj-icon-library-off:before {
  content: '\e91e';
}
.maj-icon-profile:before {
  content: '\e908';
}
.maj-icon-profile-off:before {
  content: '\e91f';
}
.maj-icon-drag:before {
  content: '\e909';
}
.maj-icon-close:before {
  content: '\e90a';
}
.maj-icon-back:before {
  content: '\e90b';
}
.maj-icon-chevron-left:before {
  content: '\e90c';
}
.maj-icon-chevron-right:before {
  content: '\e90d';
}
.maj-icon-menu-ios:before {
  content: '\e90e';
}
.maj-icon-menu-android:before {
  content: '\e90f';
}
.maj-icon-share-ios:before {
  content: '\e910';
}
.maj-icon-share-android:before {
  content: '\e911';
}
.maj-icon-airplay:before {
  content: '\e912';
}
.maj-icon-settings:before {
  content: '\e913';
}
.maj-icon-report-issue:before {
  content: '\e914';
}
.maj-icon-play:before {
  content: '\e915';
}
.maj-icon-pause:before {
  content: '\e916';
}
.maj-icon-chromecast:before {
  content: '\e917';
}
.maj-icon-download:before {
  content: '\e918';
}
.maj-icon-download-filled:before {
  content: '\e919';
}
.maj-icon-lock:before {
  content: '\e91a';
}
.maj-icon-check:before {
  content: '\e91b';
}
.maj-icon-backward-30:before {
  content: '\e901';
}
.maj-icon-backward-15:before {
  content: '\e902';
}
.maj-icon-forward-15:before {
  content: '\e903';
}
.maj-icon-forward-30:before {
  content: '\e920';
}
.maj-icon-clock:before {
  content: '\e923';
}
.maj-icon-wifi:before {
  content: '\e92e';
}
.maj-icon-linkedin:before {
  content: '\e924';
}
.maj-icon-twitter:before {
  content: '\e929';
}
.maj-icon-instagram:before {
  content: '\e92a';
}
.maj-icon-facebook:before {
  content: '\e935';
}
.maj-icon-read:before {
  content: '\e936';
}
