@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('../public/fonts/Majelan.css');

@layer base {
  @font-face {
    font-family: 'Majelan';

    src: local('Majelan'), url('/fonts/Majelan.ttf') format('truetype');

    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  html {
    @apply font-sans font-normal antialiased bg-tuna text-white;
    text-rendering: optimizeLegibility;
  }

  body {
    @apply font-sans font-normal text-white;
  }

  h1 {
    @apply text-2-5xl md:text-4-5xl font-bold text-white;
  }

  h2 {
    @apply text-2xl font-bold text-white;
  }

  h3 {
    @apply text-xl font-bold text-white;
  }

  h4 {
    @apply text-sm font-normal uppercase text-white;
  }

  p {
    @apply text-lg font-normal text-cloud;
  }

  input {
    @apply font-sans;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: #343546;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #c0c1c5;
  }
}

@layer components {
  .auth-nav::before {
    content: '';

    @apply absolute top-0 left-0 bottom-0 h-full w-1 bg-gradient-to-b from-lightBlue to-heliotrope;
  }

  .hero::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 102px;
    background: linear-gradient(to top, transparent 0%, transparent 40%, #1f202b 100%);
  }

  .hero::after {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 102px;
    background: linear-gradient(to bottom, transparent 0%, transparent 10%, #1f202b 100%);
  }

  .catalog > div:nth-child(n + 5) {
    @apply hidden;
  }

  .richtext * {
    @apply text-white;
  }

  @screen md {
    .catalog > div:nth-child(n + 5) {
      @apply block;
    }
  }
}
